exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-admin-tsx": () => import("./../../../src/pages/admin.tsx" /* webpackChunkName: "component---src-pages-admin-tsx" */),
  "component---src-pages-contact-tsx": () => import("./../../../src/pages/contact.tsx" /* webpackChunkName: "component---src-pages-contact-tsx" */),
  "component---src-pages-drinks-menu-tsx": () => import("./../../../src/pages/drinksMenu.tsx" /* webpackChunkName: "component---src-pages-drinks-menu-tsx" */),
  "component---src-pages-el-lounge-tsx": () => import("./../../../src/pages/elLounge.tsx" /* webpackChunkName: "component---src-pages-el-lounge-tsx" */),
  "component---src-pages-events-club-tsx": () => import("./../../../src/pages/eventsClub.tsx" /* webpackChunkName: "component---src-pages-events-club-tsx" */),
  "component---src-pages-events-day-tsx": () => import("./../../../src/pages/eventsDay.tsx" /* webpackChunkName: "component---src-pages-events-day-tsx" */),
  "component---src-pages-food-layout-tsx": () => import("./../../../src/pages/foodLayout.tsx" /* webpackChunkName: "component---src-pages-food-layout-tsx" */),
  "component---src-pages-food-menu-tsx": () => import("./../../../src/pages/foodMenu.tsx" /* webpackChunkName: "component---src-pages-food-menu-tsx" */),
  "component---src-pages-index-layout-tsx": () => import("./../../../src/pages/indexLayout.tsx" /* webpackChunkName: "component---src-pages-index-layout-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-latin-club-layout-tsx": () => import("./../../../src/pages/latinClubLayout.tsx" /* webpackChunkName: "component---src-pages-latin-club-layout-tsx" */),
  "component---src-pages-latin-club-tsx": () => import("./../../../src/pages/latinClub.tsx" /* webpackChunkName: "component---src-pages-latin-club-tsx" */),
  "component---src-pages-register-tsx": () => import("./../../../src/pages/register.tsx" /* webpackChunkName: "component---src-pages-register-tsx" */),
  "component---src-pages-visit-us-tsx": () => import("./../../../src/pages/visitUs.tsx" /* webpackChunkName: "component---src-pages-visit-us-tsx" */)
}

